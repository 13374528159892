<template>
  <div class="bg-light custom-padding-top">
    <div class="text-center home bg-light">
      <OurHotelsInSomaliland />
    </div>
    <div class="text-center home bg-light">
      <router-view class="bg-light" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OurHotelsInSomaliland from "@/components/MudhaHotelsSomalilandPage/OurHotelsInSomaliland.vue";

export default {
    title: 'Mudha Hotels in Somaliland',
  name: "MudhaHotelsSomaliland",
  components: {
    OurHotelsInSomaliland,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.custom-padding-top {
  padding-top: 151.5px;
}
</style>
