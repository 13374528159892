<template>
  <div class="container">
    <div class="container">
      <div class="row justify-content-sm-center d-flex">
        <h2 class="home mt-2">All Our Hotels in Somaliland</h2>
        <p class="p-copy mt-3 mb-5">
          Somaliland is in the Horn of Africa, in fact it makes the horn of the
          Horn of Africa,Somaliland is in the Horn of Africa, in fact it makes
          the horn of the Horn of AfricaSomaliland is in the Horn of Africa, in
          fact it makes the horn of the Horn of Africa
        </p>
      </div>
      <div class="row d-flex justify-content-center">
        <OurHotelInSomalilandCard
          v-for="hotel in hotels"
          :key="hotel.id"
          :hotel="hotel"
          class="mb-3 m-0 p-0 col-lg-3 col-sm-6"
          @show-on-map="showOnMap"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OurHotelInSomalilandCard from "./OurHotelInSomalilandCard.vue";

export default {
  name: "OurDestinations",
  components: {
    OurHotelInSomalilandCard,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showOnMap(coordinates) {
      this.coordinates = coordinates;
    },

    // filters hotels by search query
  },
    computed: {
    hotels() {
      return this.$store.state.hotels;
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  color: #0057b7;
  font-family: "Alata", sans-serif;
}
p{
  font-size:14px
}

</style>
