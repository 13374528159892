<template>
  <div class="card border shadow-sm-custom mb-5 mx-2 ">
    <!-- <img :src="`@/assets/img/hotels/${hotel.img[0]}.jpg`" class="card-img-top " alt="..."/> -->
    <img :src="require(`@/assets/img/hotels/${hotel.img[0]}.jpg`)" class="card-img-top " alt="..."/>
        <div class="card-body">
          <div class="d-flex align-items-start mb-0 px-0">
              <h5 class="mb-0 card-title">{{ hotel.name }}</h5>
               <HotelCardRating :id="hotel.id" :rating="hotel.rating"  />
                <button  @click="isFavorite = !isFavorite" type="button" class="btn btn-outline-danger btn-sm "><i :class="`${isFavorite ? 'fas' : 'far'} fa-heart`"></i></button>
          </div>
        <div class="text-muted mb-2 text-start">
                    <i class="fas fa-map-marker-alt"></i>
                     <span class="loc_font"> {{ hotel.location.city }}</span><br/>
                      <span class="loc_font">{{ hotel.location.distance_from_center }} km from center</span>
                </div>
                 <div class="mb-2 text-start">
                    <span class="badge bg-primary loc_font">{{ hotel.ratingnumber }}</span>
                    <a href="#!" class="text-muted loc_font">({{ hotel.ratingnumberdesc }})</a>
                </div>
               <div class="d-flex flex-wrap    ">
                    <div v-for="(facility, i) in hotel.facilities" :key="i" class="mx-2">
                         <i class="  text-secondary amnities" :class="facilityIcons[facility]"></i>
                             <span class="text-muted  card-text-custom  ms-1">{{ facility }}</span>
                    </div>
                </div>
            <router-link :to="`/mudhahotel/${hotel.id}`" @click.native="scrollToTop">
                     <button type="button" class="btn btn-primary btn-sm mt-3 mb-3 shadow-sm"> Check Rooms<font-awesome-icon icon="chevron-right" class="ficon ms-2" />
                     </button>
                </router-link>
            </div>
     </div>
</template>

<script>
// import { imagePath } from "@/mixins/imagePath.js";
import HotelCardRating from "./HotelCardRating";
import facilityIcons from "@/assets/data/maps/facility-icons";

export default {
  name: "HotelCard",
    methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

  },
  components: {
    HotelCardRating,
  },
  props: ["hotel"],
    //  mixins: [imagePath],
  data() {
    return {
      isFavorite: false,
      facilityIcons,


    };
  },
};
</script>
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
.amnities img {
  max-height: 20px;
}
.loc_font {
  font-size: 13px;
  font-weight: 550;
}
.card-title {
  font-family: "Alata", sans-serif;

  font-weight: 550;
  color: #0057b7;
}
.ficon {
  font-size: 0.8em;
  margin-top: 7px;
  float: right;
}

.home {
  color: #0057b7;
  font-family: "Alata", sans-serif;
}
.card-body {
  flex: 1 1 auto;
  padding: .5rem 1rem;
}
.shadow-sm-custom{
    border-radius:10px
}
.shadow-sm-custom:hover {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
  cursor: pointer;

}
.home {
  color: #0057b7;
  font-family: "Alata", sans-serif;
}
.card-text-custom {
  font-size:14px
}
.amnities{
  font-size:13px
}
.amnities2{
  font-size:13px;
  font-weight:550
}
</style>
